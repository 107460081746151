import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import LockIcon from '@mui/icons-material/Lock';
import styled from 'styled-components';

const StyledDivSpacer = styled.div`
	height: 4rem;
`;

function AdminDashboard() {
    const navigate = useNavigate();

    const sendToSignUp = () => {
        navigate('/admin-dashboard/sign-up');
    }

    const sendToChangePassword = () => {
        navigate('/admin-dashboard/change-password');
    }

    const sendToDeleteUser = () => {
        navigate('/admin-dashboard/delete-user');
    }

    return (
        <>
            <StyledDivSpacer />
            <Box sx={{ width: '100%', maxWidth: 800 }}>
                <Typography variant="h2" gutterBottom>
                    Opciones de administrador

                </Typography>
                <StyledDivSpacer />
                <Typography variant="h5" gutterBottom>
                    En esta sección se podrán realizar las siguientes acciones:
                </Typography>
                <StyledDivSpacer />
            </Box>
            <Stack spacing={2} direction="row">
                <Button variant="contained" color="primary" startIcon={<AddCircleOutlineIcon />} onClick={sendToSignUp}>Registrar Usuario</Button>
                <Button variant="contained" color="warning" startIcon={<LockIcon/>} onClick={sendToChangePassword} >Cambiar Contraseña</Button>
                <Button variant="contained" startIcon={<DeleteIcon />} color="error" onClick={sendToDeleteUser} >Eliminar Usuario</Button>
            </Stack>
        </>
    )
}

export { AdminDashboard };