import Swal from 'sweetalert2';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useState } from 'react';
import styled from 'styled-components';

const StyledLabelError = styled.label`
	color: red;
	font-size: 12px;
`;

const defaultTheme = createTheme();

const ChangePassword = () => {
    const [emailError, setEmailError] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const [secondPasswordError, setSecondPasswordError] = useState('')


    const setNewPassword = async (formData, event) => {
        await fetch(`${import.meta.env.VITE_API_URL}/user/`, {
            method: 'PUT',
            body: JSON.stringify(formData),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.hasOwnProperty('detail')) {
                    Swal.fire({
                        title: "Ha ocurrido un error",
                        text: data.detail,
                        icon: "error"
                    });
                }
                if (data.hasOwnProperty('success') && data.success) {
                    Swal.fire({
                        title: "Contraseña actualizada",
                        icon: "success"
                    });
                    event.target.reset();
                }
            })
            .catch((err) => {
                console.log("error - setNewPassword");
            });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        let email = data.get('email')
        let password = data.get('password')
        let passwordRepeated = data.get('password-repeat')

        setEmailError('')
        setPasswordError('')
        setSecondPasswordError('')

        if (email === '') {
            setEmailError('Ingrese un email')
            return
        }

        if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
            setEmailError('Introduzca un email válido')
            return
        }

        if (password === '') {
            setPasswordError('Ingrese una contraseña')
            return
        }

        if (passwordRepeated === '') {
            setSecondPasswordError('Ingrese nuevamenta la contraseña')
            return
        }

        if (password !== passwordRepeated) {
            Swal.fire({
                title: "Ha habido un problema",
                text: "Las contraseñas no coinciden",
                icon: "warning"
            });
            return
        }

        const formData = {
            email,
            password
        }

        setNewPassword(formData, event);
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <ManageAccountsIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Cambiar contraseña de usuario
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="email"
                                    label="Correo electrónico"
                                    type="text"
                                    id="password"
                                    autoComplete="off"
                                />
                                <StyledLabelError>{emailError}</StyledLabelError>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="password"
                                    label="Contraseña"
                                    type="password"
                                    id="password"
                                    autoComplete="off"
                                />
                                <StyledLabelError>{passwordError}</StyledLabelError>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="password-repeat"
                                    label="Repetir la contraseña"
                                    type="password"
                                    id="password-repeat"
                                    autoComplete="off"
                                />
                                <StyledLabelError>{secondPasswordError}</StyledLabelError>
                            </Grid>
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Cambiar contraseña
                        </Button>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}

export { ChangePassword };