import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { AppProvider } from '../AuthContext'
import { Navbar } from '../../Components/Navbar'
import { Layout } from '../../Components/Layout';
import { AppRoutes } from './AppRoutes'
import './App.css'

const App = () => {

	return (
		<BrowserRouter>
			<AppProvider>
				<Navbar />
				<Layout>
					<AppRoutes />
				</Layout>
			</AppProvider>
		</BrowserRouter>
	)
}

export { App };


