import image from '../../assets/ghost.png';
import styled from 'styled-components';

const StyledDiv = styled.div`
    text-align: center;
    margin-top: 30px;
`;

const StyledTitle = styled.h1`
    font-size: 2rem;
    color: #333;
`;

const StyledParagraph = styled.p`
    font-size: 1.2rem;
    color: #333;
`;

const StyledImage = styled.img`
    max-width: 100%;
    height: auto;
`;

function NotFound() {
    return (
        <>
            <StyledDiv>
                <StyledImage src={image} alt="Ghost" />
                <StyledTitle>Esta página no existe</StyledTitle>
                <StyledParagraph>Has ingresado a una ruta inexistente para el sistema actual. Contacta al administrador para validar.</StyledParagraph>
            </StyledDiv>
        </>
    )
}

export { NotFound };