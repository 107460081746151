import React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSessionStorage } from './useSessionStorage';
import Swal from 'sweetalert2';

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const { item: userData, saveItem: saveDataUser, deleteItem: deleteDataUser, updateTheRows, updateTable, listData, updateListData } = useSessionStorage('authData', {});
  const navigate = useNavigate();

  const refreshToken = async (accessToken, refreshToken, saveDataUser, logout) => {
    await fetch(`${import.meta.env.VITE_API_URL}/user/refresh_token`, {
      method: 'POST',
      body: JSON.stringify({
        refresh_token: refreshToken
      }),
      credentials: 'include',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'Authorization': `Bearer ${accessToken}`
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.hasOwnProperty('access_token') && data.hasOwnProperty('refresh_token')) {
          saveDataUser(data);
        }

        if (data.hasOwnProperty('error') || data.hasOwnProperty('detail')) {
          logout();
          Swal.fire({
            title: "Ha habido un problema al actualizar la sesión",
            text: "Sesión finalizada",
            icon: "error"
          });
        }
      })
      .catch((err) => {
        console.log("error - refreshToken");
        Swal.fire({
          title: "Error",
          icon: "error"
        });
      })
  }

  const logout = () => {
    localStorage.setItem('loggedOut', Date.now());
    deleteDataUser();
    navigate('/');
  }

  useEffect(() => {
    const interval = setInterval(() => {
      refreshToken(userData.access_token, userData.refresh_token, saveDataUser, logout);
    }, 12 * 60 * 1000);

    return () => clearInterval(interval);
  }, [userData]);

  return (
    <AppContext.Provider value={{
      userData,
      saveDataUser,
      deleteDataUser,
      updateTheRows,
      updateTable,
      listData,
      updateListData
    }} >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
