import styled from 'styled-components';

const StyledDiv = styled.div`
    margin-top: 60px;
`;

const Layout = ({ children }) => {
    return (
        <StyledDiv className="flex flex-col items-center">
            {children}
        </StyledDiv>
    )
}

export { Layout };