import Swal from 'sweetalert2';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useState } from 'react';
import styled from 'styled-components';

const StyledLabelError = styled.label`
	color: red;
	font-size: 12px;
`;

const defaultTheme = createTheme();

const SignUp = () => {
    const [admin, setAdmin] = useState(false);
    const [spammer, setSpammer] = useState(false);
    const [seller, setSeller] = useState(true);
    const [nameError, setNameError] = useState('')
    const [lastNameError, setLastNameError] = useState('')
    const [emailError, setEmailError] = useState('')
    const [passwordError, setPasswordError] = useState('')

    const handleSellerSwitch = (event) => {
        setAdmin(false);
        setSpammer(false);
        setSeller(event.target.checked);
    };

    const handleSpammerSwitch = (event) => {
        setAdmin(false);
        setSpammer(event.target.checked);
        setSeller(false);
    };

    const handleAdminSwitch = (event) => {
        setAdmin(event.target.checked);
        setSpammer(false);
        setSeller(false);
    };

    const registerUser = async (formData, event) => {
        await fetch(`${import.meta.env.VITE_API_URL}/user/`, {
            method: 'POST',
            body: JSON.stringify(formData),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.hasOwnProperty('detail')) {
                    Swal.fire({
                        title: "Ha ocurrido un error",
                        text: data.detail,
                        icon: "error"
                    });
                }
                if (data.hasOwnProperty('success') && data.success) {
                    Swal.fire({
                        title: "Usuario registrado",
                        icon: "success"
                    });
                    event.target.reset();
                    setAdmin(false);
                }
            })
            .catch((err) => {
                Swal.fire({
                    title: "Error",
                    text: err.message,
                    icon: "error"
                });
            });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        let firstName = data.get('firstName')
        let lastName = data.get('lastName')
        let email = data.get('email')
        let password = data.get('password')
        let role;

        if (seller) {
            role = 1085
        }
        if (spammer) {
            role = 1197
        }
        if (admin) {
            role = 1356
        }

        setNameError('')
        setLastNameError('')
        setEmailError('')
        setPasswordError('')

        if (firstName === '') {
            setNameError('Ingrese un nombre')
            return
        }

        if (lastName === '') {
            setLastNameError('Ingrese un apellido')
            return
        }

        if (email === '') {
            setEmailError('Ingrese un email')
            return
        }

        if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
            setEmailError('Introduzca un email válido')
            return
        }

        if (password === '') {
            setPasswordError('Ingrese una contraseña')
            return
        }

        const formData = {
            name: `${firstName} ${lastName}`,
            email,
            password,
            role
        }

        registerUser(formData, event);
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <PersonAddIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Registro de usuarios
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    autoComplete="given-name"
                                    name="firstName"
                                    id="firstName"
                                    required
                                    fullWidth
                                    label="Nombres"
                                    autoFocus
                                />
                                <StyledLabelError>{nameError}</StyledLabelError>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    fullWidth
                                    name="lastName"
                                    id="lastName"
                                    label="Apellidos"
                                    autoComplete="family-name"
                                />
                                <StyledLabelError>{lastNameError}</StyledLabelError>
                            </Grid>
                            {/* <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    name="role"
                                    id="role"
                                    label="Puesto de trabajo"
                                />
                            </Grid> */}
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="email"
                                    id="email"
                                    label="Correo electrónico"
                                    autoComplete="off"
                                />
                                <StyledLabelError>{emailError}</StyledLabelError>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="new-password"
                                />
                                <StyledLabelError>{passwordError}</StyledLabelError>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Switch color="primary" />}
                                    label="Vendedor"
                                    labelPlacement="end"
                                    checked={seller}
                                    onChange={handleSellerSwitch}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Switch color="primary" />}
                                    label="Spammer"
                                    labelPlacement="end"
                                    checked={spammer}
                                    onChange={handleSpammerSwitch}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Switch color="primary" />}
                                    label="Administrador"
                                    labelPlacement="end"
                                    checked={admin}
                                    onChange={handleAdminSwitch}
                                />
                            </Grid>
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Registrar usuario
                        </Button>
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}

export { SignUp };