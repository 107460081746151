import React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { deepPurple } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AppContext } from '../AuthContext';
import { decodeJWT } from '../../Components/Utilities';
import styled from 'styled-components';

const StyledDivSpacer = styled.div`
	height: 1rem;
`;

const defaultTheme = createTheme();

const ROLES = {
    1085: 'Vendedor',
    1197: 'Spammer',
    1356: 'Administrador',
}

const MyAccount = () => {
    const {
        userData,
    } = React.useContext(AppContext);

    let payload;
    let isAuthenticated;
    let fullName;
    let nameInitials;
    let userEmail;
    let roleId;

    if (userData.hasOwnProperty('access_token')) {
        payload = decodeJWT(userData.access_token);
        isAuthenticated = roleId === 1085 || roleId === 1197 || roleId === 1356;
        fullName = payload.name;
        nameInitials = fullName.split(" ").map((n) => n[0]).join("");
        userEmail = payload.email;
        roleId = payload.role;
    }

    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ bgcolor: deepPurple[500], width: 50, height: 50 }}> {nameInitials.substring(0, 2)} </Avatar>
                    <StyledDivSpacer />
                    <Typography component="h1" variant="h5">
                        {isAuthenticated ? fullName : ''}
                    </Typography>
                    <StyledDivSpacer />
                    <StyledDivSpacer />
                    <StyledDivSpacer />
                    <Box>
                        <Typography variant="body1" gutterBottom>
                            Correo electrónico: {userEmail}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Puesto: {ROLES[roleId]}
                        </Typography>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}

export { MyAccount };
