import React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import { Link } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useState } from 'react';
import { AppContext } from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Swal from 'sweetalert2';

const StyledLabelError = styled.label`
	color: red;
	font-size: 12px;
`;

const StyledLink = styled(Link)`
	margin: 0;
	font-family: "Roboto","Helvetica","Arial",sans-serif;
	font-weight: 400;
	font-size: 0.875rem;
	line-height: 1.43;
	letter-spacing: 0.01071em;
	color: #1976d2;
	-webkit-text-decoration: underline;
	text-decoration: underline;
	text-decoration-color: rgba(25, 118, 210, 0.4);
	&:hover {
		text-decoration-color: inherit;
	}
`;

const defaultTheme = createTheme();


const LogIn = () => {
	const { saveDataUser } = React.useContext(AppContext);
	const [emailError, setEmailError] = useState('')
	const [passwordError, setPasswordError] = useState('')
	const navigate = useNavigate();

	const Toast = Swal.mixin({
		toast: true,
		position: "top-end",
		showConfirmButton: false,
		timer: 3000,
		timerProgressBar: true,
		didOpen: (toast) => {
			toast.onmouseenter = Swal.stopTimer;
			toast.onmouseleave = Swal.resumeTimer;
		}
	});

	const sendCredentials = async (email, password) => {
		await fetch(`${import.meta.env.VITE_API_URL}/login/`, {
			method: 'POST',
			body: JSON.stringify({
				email,
				password
			}),
			credentials: 'include',
			headers: {
				'Content-type': 'application/json; charset=UTF-8'
			},
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.hasOwnProperty('access_token')) {
					saveDataUser(data);
					navigate('/module-one');
					Toast.fire({
						icon: "success",
						title: "Sesión iniciada correctamente"
					});
				}
				if (data.hasOwnProperty('error')) {
					Swal.fire({
						title: "Ha ocurrido un error",
						text: data.error,
						icon: "error"
					});
				}
			})
			.catch((err) => {
				Swal.fire({
					title: "Ocurrió un error",
					text: err.message,
					icon: "error"
				});
			});
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);
		let email = data.get('email')
		let password = data.get('password')

		setEmailError('')
		setPasswordError('')

		if (email === '') {
			setEmailError('Ingrese un email')
			return
		}

		if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
			setEmailError('Introduzca un email válido')
			return
		}

		if (password === '') {
			setPasswordError('Ingrese una contraseña')
			return
		}

		sendCredentials(email, password);

	};

	const randomWallpaper = 'https://unsplash.it/1920/1080/?random';
	const wallpaper = 'https://images.unsplash.com/photo-1542396601-dca920ea2807?q=80&w=1951&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D';

	return (
		<ThemeProvider theme={defaultTheme}>
			<Grid container component="main" sx={{ height: '100vh' }}>
				<CssBaseline />
				<Grid
					item
					xs={false}
					sm={4}
					md={7}
					sx={{
						backgroundImage: `url(${randomWallpaper})`,
						backgroundRepeat: 'no-repeat',
						backgroundColor: (t) =>
							t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
						backgroundSize: 'cover',
						backgroundPosition: 'center',
					}}
				/>
				<Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
					<Box
						sx={{
							my: 8,
							mx: 4,
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
						}}
					>
						<Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
							<LockOutlinedIcon />
						</Avatar>
						<Typography component="h1" variant="h5">
							Iniciar sesión
						</Typography>
						<Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
							<TextField
								margin="normal"
								required
								fullWidth
								id="email"
								label="Correo electrónico"
								name="email"
								autoComplete="off"
								autoFocus
							/>
							<StyledLabelError>{emailError}</StyledLabelError>
							<TextField
								margin="normal"
								required
								fullWidth
								name="password"
								label="Contraseña"
								type="password"
								id="password"
								autoComplete="current-password"
							/>
							<StyledLabelError>{passwordError}</StyledLabelError>
							<br></br>
							<Button
								type="submit"
								fullWidth
								variant="contained"
								sx={{ mt: 3, mb: 2 }}
							>
								INICIAR SESIÓN
							</Button>
						</Box>
					</Box>
				</Grid>
			</Grid>
		</ThemeProvider>
	);
}


export { LogIn };