import { useContext, useState } from 'react'
import { useRoutes, Navigate, useNavigate } from 'react-router-dom'
// import { ForgotPassword } from '../ForgotPassword'
import { SignUp } from '../SignUp'
import { LogIn } from '../LogIn'
import { AdminDashboard } from '../AdminDashboard'
import { ChangePassword } from '../ChangePassword'
import { DeleteUser } from '../DeleteUser'
import { MyAccount } from '../MyAccount'
import { ModuleOne } from '../ModuleOne'
import { ModuleTwo } from '../ModuleTwo'
import { ModuleThree } from '../ModuleThree'
import { ModuleFour } from '../ModuleFour'
import { NotFound } from '../NotFound'
import { SendToSearch } from './sendToSearch'

import { AppContext } from '../AuthContext';
import { decodeJWT, verifyIdentity } from '../../Components/Utilities';

const AppRoutes = () => {
    const navigate = useNavigate();
    const [roleId, setRoleId] = useState(null);
    const {
        userData,
        deleteDataUser
    } = useContext(AppContext);

    const logout = () => {
        deleteDataUser();
        navigate('/');
    }

    let payload;
    let isAuthenticated;
    let userEmail;
    let isAdmin;
    let isSpammer;
    let isSeller;

    if (userData.hasOwnProperty('access_token')) {
        payload = decodeJWT(userData.access_token);
        isAuthenticated = roleId === 1085 || roleId === 1197 || roleId === 1356;
        userEmail = payload.email;
        verifyIdentity(userEmail, userData.access_token, setRoleId, logout);
        isAdmin = roleId === 1356;
        isSpammer = roleId === 1197;
        isSeller = roleId === 1085;
    }

    let routesForThisUser = [
        { path: '/', element: isAuthenticated ? <Navigate to="/module-one" replace={true} /> : <LogIn /> },
        { path: '/*', element: <NotFound /> }
    ];

    if (isAuthenticated && isAdmin) {
        routesForThisUser.push(
            { path: '/admin-dashboard', element: <AdminDashboard /> },
            { path: '/admin-dashboard/sign-up', element: <SignUp /> },
            { path: '/admin-dashboard/change-password', element: <ChangePassword /> },
            { path: '/admin-dashboard/delete-user', element: <DeleteUser /> },
            { path: '/my-account', element: <MyAccount /> },
            { path: '/module-one', element: <ModuleOne /> },
            { path: '/module-two', element: <ModuleTwo /> },
            { path: '/module-three', element: <ModuleThree /> },
            { path: '/module-four', element: <ModuleFour /> },
        )
    }

    if (isAuthenticated && isSpammer) {
        routesForThisUser.push(
            { path: '/my-account', element: <MyAccount /> },
            { path: '/module-one', element: <ModuleOne /> },
            { path: '/module-two', element: <ModuleTwo /> },
            { path: '/module-three', element: <ModuleThree /> },
            { path: '/module-four', element: <ModuleFour /> },
        )
    }

    if (isAuthenticated && isSeller) {
        routesForThisUser.push(
            { path: '/my-account', element: <MyAccount /> },
            { path: '/module-three', element: <ModuleThree /> },
        )
    }

    let routes = useRoutes(routesForThisUser)

    return routes
}

export { AppRoutes }