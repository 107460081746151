import Swal from 'sweetalert2';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useState } from 'react';
import styled from 'styled-components';

const StyledLabelError = styled.label`
	color: red;
	font-size: 12px;
`;

const defaultTheme = createTheme();

const DeleteUser = () => {
    const [emailError, setEmailError] = useState('')
    const [confirmationError, setConfirmationError] = useState('')

    const deleteUser = async (formData, event) => {
        await fetch(`${import.meta.env.VITE_API_URL}/user/`, {
            method: 'DELETE',
            body: JSON.stringify(formData),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.hasOwnProperty('detail')) {
                    Swal.fire({
                        title: "Ha ocurrido un error",
                        text: data.detail,
                        icon: "error"
                    });
                }
                if (data.hasOwnProperty('success') && data.success) {
                    Swal.fire({
                        title: "Eliminación exitosa",
                        text: data.detail,
                        icon: "success"
                    });
                    event.target.reset();
                }
                if (data.hasOwnProperty('error')) {
                    Swal.fire({
                        title: "Ocurrió un error",
                        icon: "error"
                    });
                }
            })
            .catch((err) => {
                console.log("error - deleteUser");
            });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        let email = data.get('email')
        let confirmation = data.get('confirm-delete')
        let confirmationData = confirmation.split(' ');

        setEmailError('')
        setConfirmationError('')

        if (email === '') {
            setEmailError('Ingrese un email')
            return
        }

        if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
            setEmailError('Introduzca un email válido')
            return
        }

        if (confirmation === '') {
            setConfirmationError("Escriba la confirmación")
            return
        }

        if (confirmationData[0] !== 'ELIMINAR') {
            Swal.fire({
                title: "Ha habido un problema",
                text: "Debe escribir 'ELIMINAR' con mayúsculas",
                icon: "warning"
            });
            return
        }

        if (confirmationData.length == 2 & confirmationData[0].length == 8 && confirmationData[1] !== email) {
            Swal.fire({
                title: "Ha habido un problema",
                text: "El correo electrónico no coincide con la confirmación",
                icon: "warning"
            });
            return
        }

        if (confirmation != 'ELIMINAR ' + email) {
            Swal.fire({
                title: "Ha habido un problema",
                text: "Debe escribir 'ELIMINAR' en mayúsculas seguido del correo electrónico, separados únicamente por 1 espacio",
                icon: "warning"
            });
            return
        }

        const formData = {
            email
        }

        deleteUser(formData, event);
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <PersonRemoveIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Eliminar usuario
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="email"
                                    label="Correo electrónico"
                                    type="text"
                                    id="email"
                                    autoComplete="off"
                                />
                                <StyledLabelError>{emailError}</StyledLabelError>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="button" display="block" color="red" gutterBottom>
                                    Confirma la eliminación del usuario:
                                </Typography>
                                <TextField
                                    required
                                    fullWidth
                                    name="confirm-delete"
                                    label="ELIMINAR usuario@mail.com"
                                    type="text"
                                    id="confirmation-delete"
                                    autoComplete="off"
                                />
                                <StyledLabelError>{confirmationError}</StyledLabelError>
                            </Grid>
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            color='error'
                        >
                            Eliminar Usuario
                        </Button>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}

export { DeleteUser };