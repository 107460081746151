import React from "react";
import CloseIcon from '@mui/icons-material/Close';
import styled from "styled-components";

const Input = styled.input`
  color: #000;
  font-size: 1em;
  border: 2px solid rgb(59 130 246 / 0.5);
  border-radius: 20px;
  margin: 1em;
  padding: 0.5em;

  &:focus-visible {
    outline: none;
  }
`;

const ClearButton = styled.button`
  background: #c2c2c2;
  color: #3d3d3d;
  font-size: 1em;
  margin: 0;
  padding: 8px;
  border-radius: 20px;
`;

const FilterComponent = ({ filterText, onFilter, onClear }) => {
  return (
    <>
      <Input
        id="search"
        type="text"
        placeholder="Filtrar..."
        value={filterText}
        onChange={onFilter}
      />
      <ClearButton onClick={onClear}>
        <CloseIcon />
      </ClearButton>
    </>
  );
}

export { FilterComponent };
