import { NavLink, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useContext, useState, useEffect } from 'react';
import { AppContext } from '../../Pages/AuthContext';
import { decodeJWT, verifyIdentity } from '../Utilities';
import Swal from 'sweetalert2';

// import Box from '@mui/material/Box';
// import Avatar from '@mui/material/Avatar';
// import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import Divider from '@mui/material/Divider';
// import IconButton from '@mui/material/IconButton';
// import Typography from '@mui/material/Typography';
// import Tooltip from '@mui/material/Tooltip';
// import PersonAdd from '@mui/icons-material/PersonAdd';
// import Settings from '@mui/icons-material/Settings';
// import Logout from '@mui/icons-material/Logout';

const StyledList = styled.li`
	color: ${props => props.$custom ? 'rgb(255 255 255 / 0.6)' : '#FFFFFF'}
`;

const AccountMenu = () => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	return (
		<>
			<Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
				<Tooltip title="Account settings">
					<IconButton
						onClick={handleClick}
						size="small"
						sx={{ ml: 2 }}
						aria-controls={open ? 'account-menu' : undefined}
						aria-haspopup="true"
						aria-expanded={open ? 'true' : undefined}
					>
						<Avatar sx={{ width: 32, height: 32 }}>WM</Avatar>
					</IconButton>
				</Tooltip>
			</Box>
			<Menu
				anchorEl={anchorEl}
				id="account-menu"
				open={open}
				onClose={handleClose}
				onClick={handleClose}
				PaperProps={{
					elevation: 0,
					sx: {
						overflow: 'visible',
						filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
						mt: 1.5,
						'& .MuiAvatar-root': {
							width: 32,
							height: 32,
							ml: -0.5,
							mr: 1,
						},
						'&::before': {
							content: '""',
							display: 'block',
							position: 'absolute',
							top: 0,
							right: 14,
							width: 10,
							height: 10,
							bgcolor: 'background.paper',
							transform: 'translateY(-50%) rotate(45deg)',
							zIndex: 0,
						},
					},
				}}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
			>
				<MenuItem onClick={handleClose}>
					<Avatar /> Profile
				</MenuItem>
				<MenuItem onClick={handleClose}>
					<Avatar /> My account
				</MenuItem>
				<Divider />
				<MenuItem onClick={handleClose}>
					<ListItemIcon>
						<PersonAdd fontSize="small" />
					</ListItemIcon>
					Add another account
				</MenuItem>
				<MenuItem onClick={handleClose}>
					<ListItemIcon>
						<Settings fontSize="small" />
					</ListItemIcon>
					Settings
				</MenuItem>
				<MenuItem onClick={handleClose}>
					<ListItemIcon>
						<Logout fontSize="small" />
					</ListItemIcon>
					Logout
				</MenuItem>
			</Menu>
		</>
	);
}

const Navbar = () => {
	const navigate = useNavigate();
	const [roleId, setRoleId] = useState(null);
	const activeStyle = 'underline underline-offset-4 font-bold text-cyan-300';
	const {
		userData,
		deleteDataUser,
	} = useContext(AppContext);

	const ROLES = {
		'Vendedor': 1085,
		'Spammer': 1197,
		'Administrador': 1356,
	}

	let payload;
	let isAuthenticated;
	let userEmail;
	let isAdmin;
	let isSpammer;
	let isSeller;

	const logout = () => {
		localStorage.setItem('loggedOut', Date.now());
		deleteDataUser();
		navigate('/');
	}

	if (userData.hasOwnProperty('access_token')) {
		payload = decodeJWT(userData.access_token);
		isAuthenticated = roleId === 1085 || roleId === 1197 || roleId === 1356;
		userEmail = payload.email;
		verifyIdentity(userEmail, userData.access_token, setRoleId, logout);
		isAdmin = roleId === ROLES['Administrador'];
		isSpammer = roleId === ROLES['Spammer'];
		isSeller = roleId === ROLES['Vendedor'];
	}

	useEffect(() => {
		const handleLogout = (event) => {
			if (event.key === 'loggedOut') {
				deleteDataUser();
				if (location.pathname !== '/') {
					navigate('/');
				}
			}
		};

		window.addEventListener('storage', handleLogout);

		return () => {
			window.removeEventListener('storage', handleLogout);
		};
	}, [navigate, location.pathname]);

	return (
		<nav className='flex justify-between items-center fixed z-10 top-0 w-full py-5 px-8 text-sm font-light'
			style={{ background: 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(116,32,102,1) 39%, rgba(9,118,157,1) 100%)' }}
		>
			<ul className='flex items-center gap-3'>
				<StyledList>
					{isAuthenticated && (isAdmin || isSpammer) ? (<NavLink
						to='/module-one'
						className={({ isActive }) => isActive ? activeStyle : 'undefined'}
					>
						Listas
					</NavLink>) : null}

				</StyledList>
				<StyledList>
					{isAuthenticated && (isAdmin || isSpammer) ? (<NavLink
						to='/module-two'
						className={({ isActive }) => isActive ? activeStyle : 'undefined'}
					>
						Importación
					</NavLink>) : null}
				</StyledList>
				<StyledList>
					{isAuthenticated && (isAdmin || isSpammer || isSeller) ? (<NavLink
						to='/module-three'
						className={({ isActive }) => isActive ? activeStyle : 'undefined'}
					>
						Búsqueda
					</NavLink>) : null}
				</StyledList>
				<StyledList>
					{isAuthenticated && (isAdmin || isSpammer) ? (<NavLink
						to='/module-four'
						className={({ isActive }) => isActive ? activeStyle : 'undefined'}
					>
						Depuración
					</NavLink>) : null}

				</StyledList>
			</ul>


			<ul className='flex items-center gap-3'>
				<StyledList $custom={true}>
					{isAuthenticated ? (userEmail) : null}
				</StyledList>
				<StyledList>
					{isAdmin ? (<NavLink
						to='/admin-dashboard'
						className={({ isActive }) => isActive ? activeStyle : 'undefined'}
					>
						Panel administrativo
					</NavLink>) : null}
				</StyledList>
				<StyledList>
					{isAuthenticated ? (<NavLink
						to='/my-account'
						className={({ isActive }) => isActive ? activeStyle : 'undefined'}
					>
						Mi cuenta
					</NavLink>) : null}
				</StyledList>
				<StyledList>
					{/* <AccountMenu /> */}
					<NavLink
						to='/'
						className={({ isActive }) => isActive ? activeStyle : 'undefined'}
						onClick={isAuthenticated ? logout : ''}
					>
						{isAuthenticated ? 'Cerrar sesión' : 'Iniciar sesión'}
					</NavLink>
				</StyledList>
			</ul>
		</nav>
	)
}

export { Navbar };