import Swal from 'sweetalert2';

const decodeJWT = (accessToken) => {
    const parts = accessToken.split('.');
    if (parts.length !== 3) {
        throw new Error('Invalid Token');
    }

    const payload = parts[1];
    const decodedPayload = atob(payload);

    try {
        return JSON.parse(decodedPayload);
    } catch (e) {
        throw new Error('Error parsing JWT payload');
    }
}

const verifyIdentity = async (email, token, setRoleId, logout) => {
    await fetch(`${import.meta.env.VITE_API_URL}/user/verify_identity`, {
        method: 'POST',
        body: JSON.stringify({
            email
        }),
        credentials: 'include',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': `Bearer ${token}`
        },
    })
        .then((response) => response.json())
        .then((data) => {
            if (data.hasOwnProperty('success') && data.success) {
                setRoleId(data.message.role)
            }

            if (data.hasOwnProperty('error')) {
                Swal.fire({
                    title: "Ha habido un problema",
                    text: data.message,
                    icon: "warning"
                });
            }

            if (data.hasOwnProperty('detail')) {
                logout();
                Swal.fire({
                    title: "Error",
                    text: "Sesión finalizada por actividad maliciosa",
                    icon: "error"
                });
            }
        })
        .catch((err) => {
            console.log("error - verifyIdentity");
            Swal.fire({
                title: "Error",
                icon: "error"
            });
        })
}

export { decodeJWT, verifyIdentity };