import React from 'react';

const useSessionStorage = (itemName, initialValue) => {
    const [item, setItem] = React.useState(initialValue);
    const [updateTheRows, setUpdateTheRows] = React.useState(false);
    const [listData, setListData] = React.useState([])

    React.useEffect(() => {
        try {
            const sessionStorageItem = sessionStorage.getItem(itemName);
            let parsedItem;

            if (!sessionStorageItem) {
                sessionStorage.setItem(itemName, JSON.stringify(initialValue));
                parsedItem = initialValue;
            } else {
                parsedItem = JSON.parse(sessionStorageItem);
                setItem(parsedItem);
            }
        } catch (error) {
            console.log("error - useSessionStorage");
        }

    }, []);

    const saveItem = (newItem) => {
        sessionStorage.setItem(itemName, JSON.stringify(newItem));
        setItem(newItem);
    }

    const deleteItem = () => {
        sessionStorage.removeItem(itemName);
        setItem({});
    }

    const updateTable = () => {
        setUpdateTheRows(!updateTheRows);
    }

    const updateListData = (newContent) => {
        setListData(newContent);
    }

    return {
        item,
        saveItem,
        deleteItem,
        updateTheRows,
        updateTable,
        listData,
        updateListData
    }
}

export { useSessionStorage };