import differenceBy from 'lodash/differenceBy';
import Swal from 'sweetalert2';
import { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import DataTable, { createTheme } from "react-data-table-component";
import { FilterComponent } from "./FilterComponent";
import { AppContext } from '../../Pages/AuthContext';

import NoteAddIcon from '@mui/icons-material/NoteAdd';
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LinearProgress from '@mui/material/LinearProgress';

import styled from 'styled-components';

const StyledDivSpacer = styled.div`
    height: 1rem;
`;

const StyledSpan = styled.span`
    font-weight: bold;
    color: #ff0000;
`

const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
    }
});

const Table = (props) => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleCleared, setToggleCleared] = useState(false);

    const { updateTheRows, updateTable, listData, updateListData } = useContext(AppContext);

    const handleRowSelected = useCallback(state => {
        setSelectedRows(state.selectedRows);
    }, []);

    const columns = [
        {
            name: 'Lista',
            selector: row => row.table_name,
        },
        {
            name: 'Creado por',
            selector: row => row.created_by,
        },
        {
            name: 'Registros',
            selector: row => row.registers,
        },
        {
            name: 'Fecha de creación',
            selector: row => row.created_at,
            sortable: true,
        },
        {
            name: 'Fecha de actualización',
            selector: row => row.updated_at,
            sortable: true,
        },
    ];

    const [filterText, setFilterText] = useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    const filteredItems = props.data.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );

    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <FilterComponent
                onFilter={e => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
        );
    }, [filterText, resetPaginationToggle]);

    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
    };

    const customStyles = {
        rows: {
            style: {
                fontSize: '16px',
            },
        },
        headCells: {
            style: {
                fontSize: '16px',
                fontWeight: 'bold',
            },
        },
        subHeaderComponent: {
            style: {
                borderRadious: '0 20px 20px 0',
            }
        }
    };

    const DownloadListDialog = () => {
        const [open, setOpen] = useState(false);
        const [loading, setLoading] = useState(true);
        const [dialogTitle, setDialogTitle] = useState('');
        const [dialogContent, setDialogContent] = useState('');
        const [urlToDownloadCsv, setUrlToDownloadCsv] = useState('');
        const [generateCsvIsDisabled, setGenerateCsvIsDisabled] = useState(true);
        let table_names = selectedRows.map(r => r.table_name)

        let unique_file_messages = [
            "Generando archivo CSV...",
            "Se está generando el archivo CSV, en cuanto esté listo verás el botón para decargarlo.",
            "Archivo CSV listo",
            "El archivo CSV se ha generado correctamente. Haga clic en el botón para descargarlo."
        ]

        let multiple_files_messages = [
            "Generando archivos CSV...",
            "Se están generando los archivos CSV, en cuanto estén listos verás el botón para decargarlos.",
            "Archivos CSV listos",
            "Debido a la selección múltiple de archivos CSV, se han comprimidido en un único archivo. Haga clic en el botón para descargarlo."
        ]

        const handleClickOpen = () => {
            setOpen(true);
            if (table_names.length > 0 && table_names.length < 2) {
                setDialogTitle(unique_file_messages[0]);
                setDialogContent(unique_file_messages[1]);
            }

            if (table_names.length > 1) {
                setDialogTitle(multiple_files_messages[0]);
                setDialogContent(multiple_files_messages[1]);
            }
            // Debemos llamar al endpoint que gestiona la descarga si es 1 archivo o varios (devolviendo un zip)
            let fixedTableNames = table_names.map(table_name => {
                if (table_name === "Lista negra") {
                    return "black_list";
                }
                if (table_name === "Lista mala") {
                    return "bad_list";
                }
                if (table_name === "Bajas") {
                    return "unsubscribed";
                }
                return table_name;
            });
            requestDownloadFile(fixedTableNames);
        };

        const handleClose = () => {
            setOpen(false);
            setToggleCleared(!toggleCleared);
        };

        const downloadCsvFile = () => {
            const url = urlToDownloadCsv;
            window.location.href = url;
            handleClose();
            Toast.fire({
                icon: "success",
                title: "Descarga realizada"
            });
        }

        const requestDownloadFile = async (table_names) => {
            await fetch(`${import.meta.env.VITE_API_URL}/lists/prepare_file`, {
                method: 'POST',
                body: JSON.stringify({
                    collection_names: table_names
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            })
                .then((response) => response.json())
                .then((data) => {
                    setLoading(false);
                    if (data.hasOwnProperty('success')) {
                        if (table_names.length > 1) {
                            setDialogTitle(multiple_files_messages[2]);
                            setDialogContent(multiple_files_messages[3]);
                        } else {
                            setDialogTitle(unique_file_messages[2]);
                            setDialogContent(unique_file_messages[3]);
                        }
                        setUrlToDownloadCsv(data.content.file_name);
                    }
                    if (data.hasOwnProperty('detail')) {
                        handleClose();
                        Swal.fire({
                            title: "Error!",
                            text: data.detail,
                            icon: "error"
                        });
                    }
                })
                .catch((err) => {
                    console.log("error - requestDownloadFile");
                });
        };

        return (
            <>
                <Button variant="contained" onClick={handleClickOpen} startIcon={<NoteAddIcon />} color="success" autoFocus>
                    Generar CSV
                </Button>
                <Dialog
                    open={open}
                    onClose={handleClose}
                >
                    <DialogTitle> {dialogTitle} </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {dialogContent}
                        </DialogContentText>
                        <StyledDivSpacer />
                        {loading && <LinearProgress />}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cerrar</Button>
                        <Button variant="contained" startIcon={<DownloadIcon />} onClick={downloadCsvFile} >Descargar</Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    };

    const ModifyListDialog = () => {
        const [open, setOpen] = useState(false);
        const [currentListName, setCurrentListName] = useState('');
        const [remainingTableNames, setRemainingTableNames] = useState([]);
        const [tableQuantityToModify, setTableQuantityToModify] = useState(0);
        const [modifiedTableCounter, setModifiedTableCounter] = useState(0);
        const [successMessage, setSuccessMessage] = useState('')
        let table_names = selectedRows.map(r => r.table_name)

        const handleClickOpen = () => {
            if (table_names.length > 1) {
                setSuccessMessage("Modificaciones exitosas")
            } else (
                setSuccessMessage("Modificación exitosa")
            )
            let firstTableName = table_names.pop();
            setCurrentListName(firstTableName);
            setTableQuantityToModify(table_names.length);
            setRemainingTableNames(['endElement', ...table_names]);
            setOpen(true);
        };

        const handleClose = () => {
            setOpen(false);
            setToggleCleared(!toggleCleared);
        };

        const modifyLists = async (old_name, new_name) => {
            if (old_name === "Lista negra") {
                old_name = "black_list";
            }
            if (old_name === "Lista mala") {
                old_name = "bad_list";
            }
            if (old_name === "Bajas") {
                old_name = "unsubscribed";
            }
            await fetch(`${import.meta.env.VITE_API_URL}/lists/`, {
                method: 'PUT',
                body: JSON.stringify({
                    old_list_name: old_name,
                    new_list_name: new_name
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.hasOwnProperty('success')) {
                        setModifiedTableCounter(modifiedTableCounter + 1);

                        if (modifiedTableCounter == tableQuantityToModify) {
                            updateTable(!updateTheRows);
                            Swal.fire({
                                title: successMessage,
                                icon: "success"
                            });
                        }
                    }
                    if (data.hasOwnProperty('detail')) {
                        Swal.fire({
                            title: "Error",
                            text: data.detail,
                            icon: "error"
                        });
                    }
                    if (data.hasOwnProperty('error')) {
                        Swal.fire({
                            title: "Error",
                            text: data.message,
                            icon: "error"
                        });
                    }

                })
                .catch((err) => {
                    console.log("error - modifyLists");
                });
        };

        return (
            <>
                <Button variant="contained" onClick={handleClickOpen} startIcon={<EditIcon />} color="warning" autoFocus>
                    Modificar
                </Button>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                        component: 'form',
                        onSubmit: (event) => {
                            event.preventDefault();
                            const formData = new FormData(event.currentTarget);
                            let newListName = formData.get('list-name-modify')
                            modifyLists(currentListName, newListName);
                            event.target.reset();
                            let addNewListName = remainingTableNames.pop()
                            if (remainingTableNames.length > 0) {
                                setCurrentListName(addNewListName);
                            } else {
                                handleClose();
                                setToggleCleared(!toggleCleared);
                                Swal.fire({
                                    title: 'Procesando cambios',
                                    text: 'Modificaciones en curso...',
                                    allowOutsideClick: false,
                                    didOpen: () => {
                                        Swal.showLoading();
                                    }
                                });
                            }
                        },
                    }}
                >
                    <DialogTitle>Modificando <StyledSpan>{currentListName}</StyledSpan> </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Ingresa el nuevo nombre para la lista seleccionada.
                            No puedes utilizar el nombre de alguna lista existente.
                        </DialogContentText>
                        <TextField
                            autoFocus
                            required
                            margin="dense"
                            id="list-name-modify"
                            name="list-name-modify"
                            label="Nuevo nombre"
                            type="text"
                            fullWidth
                            variant="standard"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancelar</Button>
                        <Button type="submit">Modificar</Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    };

    const DeleteListDialog = () => {
        const [open, setOpen] = useState(false);
        let names = '';
        let table_names = selectedRows.map(r => r.table_name)
        table_names.forEach(name => {
            names += name + ', ';
        });
        names = names.slice(0, -2);
        const isUniqTable = table_names.length == 1

        const deleteLists = async (table_names) => {
            await fetch(`${import.meta.env.VITE_API_URL}/lists/`, {
                method: 'DELETE',
                body: JSON.stringify({
                    collection_names: table_names
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.hasOwnProperty('success')) {
                        Swal.fire({
                            title: table_names.length > 1 ? "Eliminaciones exitosas" : "Eliminación exitosa",
                            icon: "success"
                        });
                    }
                    if (data.hasOwnProperty('detail')) {
                        Swal.fire({
                            title: "Error",
                            text: data.detail,
                            icon: "error"
                        });
                    }
                })
                .catch((err) => {
                    console.log("error - deleteLists");
                });
        };

        const handleClickOpen = () => {
            setOpen(true);
        };

        const handleClose = () => {
            setOpen(false);
            setToggleCleared(!toggleCleared);
        };

        const handleAccept = () => {
            handleClose();
            let fixedTableNames = table_names.map(table_name => {
                if (table_name === "Lista negra") {
                    return "black_list";
                }
                if (table_name === "Lista mala") {
                    return "bad_list";
                }
                if (table_name === "Bajas") {
                    return "unsubscribed";
                }
                return table_name;
            });
            deleteLists(fixedTableNames);
            updateListData(differenceBy(listData, selectedRows, 'table_name'));
        };

        return (
            <>
                <Button variant="contained" onClick={handleClickOpen} startIcon={<DeleteIcon />} color="error">
                    ELIMINAR
                </Button>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        ¿Seguro de eliminar <StyledSpan>{names}</StyledSpan>?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Eliminar {isUniqTable ? 'la lista seleccionada' : 'las listas seleccionadas'} también eliminará todo su contenido.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">NO</Button>
                        <Button variant="contained" onClick={handleAccept} startIcon={<DeleteIcon />} color="error" autoFocus>
                            SI
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    };

    const contextActions = useMemo(() => {
        return (
            <Stack spacing={2} direction="row">
                <DownloadListDialog />
                <ModifyListDialog />
                <DeleteListDialog />
            </Stack>
        )
    }, [listData, selectedRows, toggleCleared]);

    const BoxBasic = () => {
        return (
            <Box component="section" sx={{ p: 2, border: '1px dashed grey' }}>
                No hay registros para mostrar
            </Box>
        );
    }

    createTheme('solarized', {
        text: {
            primary: '#268bd2',
            secondary: '#2aa198',
        },
        background: {
            default: '#002b36',
        },
        context: {
            background: '#cb4b16',
            text: '#FFFFFF',
        },
        divider: {
            default: '#073642',
        },
        action: {
            button: 'rgba(0,0,0,.54)',
            hover: 'rgba(0,0,0,.08)',
            disabled: 'rgba(0,0,0,.12)',
        },
    }, 'dark');

    return (
        <DataTable
            title="Listas de datos"
            columns={columns}
            data={filteredItems}
            pagination
            paginationComponentOptions={paginationComponentOptions}
            selectableRows
            selectableRowsRadio="radio"
            selectableRowsHighlight
            striped
            paginationRowsPerPageOptions={[10, 50, 100, 200, 500]}
            highlightOnHover
            defaultSortField="name"
            contextActions={contextActions}
            onSelectedRowsChange={handleRowSelected}
            clearSelectedRows={toggleCleared}
            customStyles={customStyles}
            subHeader
            subHeaderComponent={subHeaderComponent}
            noDataComponent={<BoxBasic />}
        // theme='solarized'
        />
    );
};

export { Table }
